import * as allApi from '@/src/api';
import * as allV2Api from '@/src/api/v2/';

type AllApi = typeof allApi;
type AllV2Api = typeof allV2Api;

type ReturnApiKeyV1 = <
  A extends keyof AllApi,
  M extends keyof AllApi[A] & string,
  MayFn extends AllApi[A][M],
  P extends MayFn extends Fn
    ? Parameters<MayFn> extends []
      ? never
      : Parameters<MayFn>[0]
    : never,
>(
  api: A,
  method: M,
  parameters?: P,
) => string | [string, P];

export const apiKey: ReturnApiKeyV1 = (api, method, parameters) => {
  const baseApiKey = `${api}.${method}`;
  if (parameters == null) {
    return baseApiKey;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return [baseApiKey, parameters];
};

type ReturnApiKeyV2 = <
  A extends keyof AllV2Api,
  M extends keyof AllV2Api[A] & string,
  MayFn extends AllV2Api[A][M],
  P extends MayFn extends Fn
    ? Parameters<MayFn> extends []
      ? never
      : Parameters<MayFn>[0]
    : never,
>(
  api: A,
  method: M,
  parameters?: P,
) => string | [string, P];

export const apiKeyV2: ReturnApiKeyV2 = (api, method, parameters) => {
  const baseApiKey = `${api}.${method}`;
  if (parameters == null) {
    return baseApiKey;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return [baseApiKey, parameters];
};
